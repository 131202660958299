import React, { useContext, useState, useEffect } from "react";
import { API } from "aws-amplify";
import { Button } from "flowbite-react";
import { FaTrash, FaEdit } from "react-icons/fa";
import { Context } from "../../../../Context/index.jsx";
import { PaginatedTable } from "../../../../common/DataDisplay/index.jsx";
import CreatePopup from "./CreatePopup.jsx";
import UpdateStaffPopup from "./UpdateStaffPopup.jsx";

const Stafflist = () => {
  const { institution } = useContext(Context).institutionData;
  const [staffData, setStaffData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedUserType, setSelectedUserType] = useState("");
  const [loading, setLoading] = useState(true);
  const [showCreatePopup, setShowCreatePopup] = useState(false);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const head = ["Name", "Email", "Phone Number", "User Type", "Actions"];

  const fetchData = async () => {
    try {
      const response = await API.get("main", `/admin/staff-list/${institution}`);
      setStaffData(response);
      setFilteredData(response);
    } catch (error) {
      console.error("Error fetching staff data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [institution]);

  const handleFilterChange = (event) => {
    const userType = event.target.value;
    setSelectedUserType(userType);
    if (userType) {
      setFilteredData(staffData.filter((staff) => staff.userType === userType));
    } else {
      setFilteredData(staffData);
    }
  };

  const handleDelete = async (cognitoId) => {
    try {
      await API.del("main", `/admin/delete-staff/${institution}/${cognitoId}`);
      setStaffData(staffData.filter((staff) => staff.cognitoId !== cognitoId));
      setFilteredData(filteredData.filter((staff) => staff.cognitoId !== cognitoId));
      alert("Staff member deleted successfully.");
    } catch (error) {
      console.error("Error deleting staff member:", error);
    }
  };

  const handleUpdate = (staff) => {
    setSelectedStaff(staff);
    setShowUpdatePopup(true);
  };

  const addNewStaff = (newStaff) => {
    setStaffData([...staffData, newStaff]);
    setFilteredData([...filteredData, newStaff]);
    setShowCreatePopup(false);
    fetchData();
  };

  return (
    <div className="p-4 mb-4">
      <h1 className="text-xl font-bold mb-4">Staff List</h1>

      <div className="mb-4">

        <label htmlFor="userType" className="mr-2"></label>
        <select
          id="userType"
          value={selectedUserType}
          onChange={handleFilterChange}
          className="border px-2 rounded"
        >
          <option value="">All</option>
          <option value="chef">Chef</option>
          <option value="waiter">Waiter</option>
          <option value="admin">Admin</option>
        </select>

      <div className="flex justify-end ">
        <Button onClick={() => setShowCreatePopup(true)} className="bg-primaryColor">
          Create Staff
        </Button>
      </div>
      </div>

      {loading ? (
  <p>Loading...</p>
) : (
  <>
    {screenWidth > 1025 ? (
      <PaginatedTable
        head={head}
        data={filteredData.map((staff) => [
          staff.name,
          staff.email,
          staff.phoneNumber,
          staff.userType,
          <div className="flex space-x-2">
            <Button onClick={() => handleUpdate(staff)} className="bg-primaryColor">
              <FaEdit />
            </Button>
            <Button onClick={() => handleDelete(staff.cognitoId)} className="bg-red-500">
              <FaTrash />
            </Button>
          </div>,
        ])}
      />
    ) : (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 p-6">
      {filteredData.map((staff) => (
        <div
          key={staff.cognitoId}
          className="relative  rounded-lg shadow-lg overflow-hidden bg-secondaryColor"
        >
        
          <div className="absolute top-0 right-0  rounded-full w-32 h-32 transform translate-x-10 -translate-y-10"></div>
    
          <div className="relative p-6">
            {/* User Info */}
            <h2 className="text-xl font-bold  mb-4">{staff.name}</h2>
            <div className="mb-2">
              <span className="block text-sm font-medium ">Email:</span>
              <span className="block ">{staff.email}</span>
            </div>
            <div className="mb-2">
              <span className="block text-sm font-medium ">Phone Number:</span>
              <span className="block ">{staff.phoneNumber}</span>
            </div>
            <div className="mb-2">
              <span className="block text-sm font-medium ">User Type:</span>
              <span
                className={`inline-block px-3 py-1 rounded-full text-sm font-bold ${
                  staff.userType === 'Admin'
                    ? 'bg-blue-600 text-white'
                    : 'bg-green-600 text-white'
                }`}
              >
                {staff.userType}
              </span>
            </div>
    
          
            <div className="flex space-x-4 mt-6">
              <button
                onClick={() => handleUpdate(staff)}
                className="flex items-center justify-center flex-1 px-4 py-2 text-white bg-primaryColor shadow-md rounded-md"
              >
                <FaEdit className="mr-2" />
                Edit
              </button>
              <button
                onClick={() => handleDelete(staff.cognitoId)}
                className="flex items-center justify-center flex-1 px-4 py-2 text-white bg-red-500 rounded-md hover:bg-red-600 shadow-md transform hover:scale-105 transition-transform duration-200"
              >
                <FaTrash className="mr-2" />
                Delete
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
    
    
    )}
  </>
)}

      {showCreatePopup && (
        <CreatePopup
          onClose={() => setShowCreatePopup(false)}
          onSuccess={addNewStaff}
        />
      )}

      {showUpdatePopup && (
        <UpdateStaffPopup
          staff={selectedStaff}
          institution={institution}
          onClose={() => setShowUpdatePopup(false)}
          onSuccess={(updatedStaff) => {
            const updatedStaffData = staffData.map((staff) =>
              staff.cognitoId === updatedStaff.cognitoId ? updatedStaff : staff
            );
            setStaffData(updatedStaffData);
            setFilteredData(updatedStaffData);
            setShowUpdatePopup(false);
          }}
        />
      )}
    </div>
  );
};

export default Stafflist;
