import Header from "../../../components/Header";
import Hero from "./Hero";
import Categories from "./Categories";
import VisitUs from "./VisitUs";

function Home() {
  return (
    <main>
      <Header />
      <Hero />
      <Categories />
      <VisitUs />
    </main>
  );
}

export default Home;