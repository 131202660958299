import React, { useState, useEffect, useContext } from "react";
import { API } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../Context";
import { getCurrencySymbol } from "../../../common/Inputs";
import { FaTimes } from "react-icons/fa";

const ViewCartPopUp = ({ isOpen, closePopup, onOrderPlaced }) => {
  const [cartItems, setCartItems] = useState([]);
  const { setLoader, institutionData, userData } = useContext(Context);
  const [name, setName] = useState("");
  const [tableName, setTableName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const storedCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    const updatedCart = storedCartItems.filter((cartItem) => {
      return !cartItem.outOfStock;
    });
    localStorage.setItem("cartItems", JSON.stringify(updatedCart));
    setCartItems(updatedCart);
  }, []);

  useEffect(() => {
    const storedCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    setCartItems(storedCartItems);
  }, []);

  useEffect(() => {
    if (userData && userData.name) {
      setName(userData.name);
    }
  }, [userData]);

  const updateQuantity = (itemId, newQuantity) => {
    const updatedCartItems =
      newQuantity <= 0
        ? cartItems.filter((item) => item.itemId !== itemId)
        : cartItems.map((item) =>
            item.itemId === itemId ? { ...item, quantity: newQuantity } : item
          );
    setCartItems(updatedCartItems);
    localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
  };

  const handleOrderPlace = async () => {
    if (!name || !tableName) {
      alert("Please enter your name and table name to place the order.");
      return;
    }
    const preparedItems = cartItems.map((item) => ({
      itemId: item.itemId,
      currency: item.currency,
      itemName: item.itemName,
      price: item.price * item.quantity,
      quantity: item.quantity,
    }));

    try {
      const response = await API.post(
        "main",
        `/any/create-order/${institutionData.institution}`,
        {
          body: {
            name,
            tableName,
            totalAmount: totalPrice,
            currency: cartItems[0].currency,
            items: preparedItems,
            ...(userData && { orderBy: userData.cognitoId }),
          },
        }
      );
      const orderId = response.orderId;
      localStorage.removeItem("orderId");
      localStorage.setItem("orderId", orderId);
      alert("Order placed successfully!");
      closePopup();
      onOrderPlaced();
      localStorage.removeItem("cartItems");
      setCartItems([]);
    } catch (error) {
      console.error("Error placing order:", error);
      alert("Failed to place order.");
    }
  };

  const totalCurrency = cartItems.length > 0 ? cartItems[0].currency : "INR";
  const totalPrice = cartItems.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 sm:w-3/4 max-w-lg">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-semibold">Your Cart</h1>
          <button
            onClick={() => {
              closePopup();
              onOrderPlaced();
            }}
            className="text-gray-500 hover:text-gray-700"
          >
            <FaTimes size={20} />
          </button>
        </div>

        {cartItems.length === 0 ? (
          <p className="text-center text-gray-500 text-bold">
            Your cart is empty.
          </p>
        ) : (
          <>
        
          <div
            className="mt-4 space-y-4"
            style={{ maxHeight: "200px", overflowY: "auto" }}
          >
            {cartItems.map((item) => (
              <div
                key={item.itemId || Math.random()}
                className="flex justify-between items-center p-4 border rounded-lg"
              >
                <div className="flex flex-col">
                  <h3 className="font-semibold text-lg">{item.itemName}</h3>
                  <p className="text-sm text-gray-600">
                    {getCurrencySymbol(item.currency)} {item.price * item.quantity}
                  </p>
                </div>
                <div className="flex items-center gap-2">
                  <button
                    className="px-2 py-1 bg-gray-200 rounded-md"
                    onClick={() =>
                      updateQuantity(item.itemId, Math.max(item.quantity - 1, 0))
                    }
                  >
                    -
                  </button>
                  <span>{item.quantity}</span>
                  <button
                    className="px-2 py-1 bg-gray-200 rounded-md"
                    onClick={() => updateQuantity(item.itemId, item.quantity + 1)}
                  >
                    +
                  </button>
                </div>
              </div>
            ))}
          </div>

          {/* Static Content */}
          <div className="mt-4 text-lg font-semibold">
            Total Price: {getCurrencySymbol(totalCurrency)}
            {totalPrice}
          </div>

          <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="w-full">
              <label
                htmlFor="name"
                className="block text-sm font-semibold text-gray-800"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                className="w-full p-3 border rounded-md"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="w-full">
              <label
                htmlFor="table_name"
                className="block text-sm font-semibold text-gray-800"
              >
                Table Name
              </label>
              <input
                type="text"
                id="table_name"
                className="w-full p-3 border rounded-md"
                placeholder="Enter your table name"
                value={tableName}
                onChange={(e) => setTableName(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="mt-4 space-x-4 flex justify-between">
            <button
              className="w-full bg-green-500 text-white py-2 px-4 rounded-lg"
              onClick={handleOrderPlace}
            >
              Place Order
            </button>
            <button
              className="w-full bg-gray-500 text-white py-2 px-4 rounded-lg"
              onClick={() => navigate("/product")}
            >
              Add More Product
            </button>
          </div>
        </>
      )}
    </div>
  </div>
  );
};

export default ViewCartPopUp;
