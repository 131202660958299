export const institutionData = {
  institution: 'Cafe-Trail',
  logoUrl:'https://institution-utils.s3.us-east-1.amazonaws.com/public/Cafe-Cg/Cafe-Trail/logo_coffe.png',
  PrimaryColor: '#806735',
  SecondaryColor:'#F9D9AA',
  LightPrimaryColor:'#F8F9FA',
  LightSecondaryColor:'#F4F5F6',
  tagLine1: 'We love good Tea',
  tagLine2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  productTagline: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  heroImage: '/images/coffee.svg'
}
export const defaultProfileImageUrl =
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyuNFyw05KSucqjifL3PhDFrZLQh7QAS-DTw&usqp=CAU80-804949_profile-icon-for-the-politics-category-circle-hd.png';