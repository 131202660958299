import { useContext, useEffect, useState } from "react";
import { API } from "aws-amplify";
import { Context } from "../../../../Context";
import { PaginatedTable } from "../../../../common/DataDisplay";
import OrderModal from "./OrderModal";
import { FaEye } from "react-icons/fa6";
import { Button } from "flowbite-react";
import { getCurrencySymbol } from "../../../../common/Inputs";

const Orders = () => {
  const { institution } = useContext(Context).institutionData;
  const [orders, setOrders] = useState([]);
  const { userData } = useContext(Context);
  const { setLoader } = useContext(Context);
  const [modalOrder, setModalOrder] = useState({});
  const [modalIndex, setModalIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [ws, setWs] = useState(null);
  const [connectionTime, setConnectionTime] = useState(0);
  const [reconnectAttempts, setReconnectAttempts] = useState(0);
  const [timers, setTimers] = useState({});
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [staffList, setStaffList] = useState([]); 
  const [chefNames, setChefNames] = useState({}); 

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      setTimers((prevTimers) => {
        const updatedTimers = { ...prevTimers };

        for (const [orderId, remainingTime] of Object.entries(updatedTimers)) {
          if (remainingTime > 0) {
            updatedTimers[orderId] = remainingTime - 1000; 
          } else {
            updatedTimers[orderId] = 0;
          }
        }

        return updatedTimers;
      });
    }, 1000);

    // Cleanup interval on unmount
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Initialize timers when orders are fetched
    const newTimers = {};
    orders.forEach((order) => {
      if (order.status === "preparing" && order.deliveryTime) {
        const timeRemaining = Math.max(order.deliveryTime - Date.now(), 0);
        newTimers[order.orderId] = timeRemaining;
      }
    });
    setTimers(newTimers);
  }, [orders]);

  const formatTime = (milliseconds) => {
    const minutes = Math.floor(milliseconds / 60000);
    const seconds = Math.floor((milliseconds % 60000) / 1000);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };
  const establishWebSocket = () => {
    if (ws) {
      console.log(
        "WebSocket connection already exists. Skipping re-establishment."
      );
      return;
    }

    console.log("Attempting to establish WebSocket connection...");
    const socket = new WebSocket(
      `wss://bdd41rpy8d.execute-api.us-east-2.amazonaws.com/dev?institution=${institution}`
    );

    socket.onopen = () => {
      console.log("WebSocket connection established");
      console.log(`Connected to WebSocket with institution: ${institution}`);
      setReconnectAttempts(0);
      setWs(socket); // Set the WebSocket instance in state
    };

    socket.onmessage = (event) => {
      console.log("WebSocket message received:", event.data);

      try {
        const updatedOrder = JSON.parse(event.data);
        console.log("Parsed order update:", updatedOrder);

        // Prevent duplicate alerts by checking existing orders
        if (!orders.some((order) => order.orderId === updatedOrder.orderId)) {
          getOrders(); // Refresh orders
          alert("New order received!"); // Show alert only for new orders
        }
      } catch (error) {
        console.error("Error parsing WebSocket message:", error);
      }
    };

    socket.onerror = (error) => {
      console.error("WebSocket error occurred:", error);
    };

    socket.onclose = (event) => {
      console.log("WebSocket connection closed:", event);
      setWs(null); // Clear the WebSocket state
      const delay = Math.min(1000 * 2 ** reconnectAttempts, 30000); // Exponential backoff
      console.log(`Reconnecting in ${delay / 1000} seconds...`);
      setTimeout(() => {
        setReconnectAttempts((prev) => prev + 1);
        establishWebSocket(); // Re-establish connection
      }, delay);
    };
  };

  useEffect(() => {
    getOrders();
    if (userData && userData.userType === "chef") {
      console.log("User is a chef. Establishing WebSocket connection...");
      establishWebSocket();
    } else {
      console.log("User is not a chef. WebSocket connection not established.");
    }

    return () => {
      console.log("Cleaning up WebSocket connection...");
      if (ws) ws.close();
    };
  }, [institution, userData]);

  const getOrders = async () => {
    if (!userData || !userData.userType || !userData.cognitoId) {
      console.log("Waiting for userData to load...");
      return; // Exit early if userData is not available yet
    }
    try {
      setLoader(true);
      const result = await API.get(
        "main",
        `/admin/list-orders/${institution}`,
        {}
      );
      console.log("Fetched orders:", result);
      const userType = userData.userType; 
      const chefCognitoId = userData.cognitoId; 
  
  
      let filteredOrders = [];
  
      if (userData && userData.userType === "chef") {
        filteredOrders = result.filter((order) => {
          console.log("Checking order:", order);
  
         
          if (order.status === "preparing" || order.status === "ready") {
            const isChefOrder = order.chefId === chefCognitoId;
           
            return isChefOrder;
          }
  
          return true;
        });
      } else {

        filteredOrders = result;
      }
  
      const sortedOrders = filteredOrders.sort((a, b) => b.createdAt - a.createdAt);

      setOrders(sortedOrders);
      if (userData.userType === "admin") {
        const staffResult = await API.get(
          "main",
          `/admin/staff-list/${institution}`,
          {}
        );
        setStaffList(staffResult);

      
        const chefNameMapping = {};
        staffResult.forEach((staff) => {
          if (staff.userType === "chef") {
            chefNameMapping[staff.cognitoId] = staff.name;
          }
        });
        setChefNames(chefNameMapping);
      }
      setLoader(false);
    } catch (e) {
      console.error("Error fetching orders:", e);
      setLoader(false);
    }
  };

  // useEffect(() => {
  //   getOrders();
  // }, [institution]);

  const head = ["Customer", "Table", "Amount", "Status", ...(userData.userType === "admin" ? ["Chef"] : ['']),"Timer", "More"];

  return (
    <>
      <div className="w-full p-8 bg-gray-100 ">
        <h1>Orders</h1>
        {screenWidth > 1025 ? (
          <PaginatedTable
            head={head}
            data={orders.map((obj, index) => [
              obj.name,
              obj.tableName,
              ` ${getCurrencySymbol(obj.currency)}${obj.totalAmount}`,
              <div className="w-16 flex justify-center items-center">
                <div
                  className={`${
                    obj.status === "ready"
                      ? "bg-green-600"
                      : obj.status === "pending"
                      ? "bg-red-600"
                      : obj.status === "preparing"
                      ? "bg-blue-600"
                      : "bg-gray-600"
                  } flex items-center justify-center p-1 mt-1 h-8 text-white font-bold px-2 rounded-md`}
                >
                  {obj.status}
                </div>
              </div>,
              userData.userType === "admin" &&  obj.chefId
                ? chefNames[obj.chefId]
                  ? chefNames[obj.chefId].includes(" ")
                    ? chefNames[obj.chefId].split(" ")[0]
                    : chefNames[obj.chefId]
                  : "Chef Not Found"
                : "-",
              obj.status === "preparing"
                ? timers[obj.orderId] > 0
                  ? formatTime(timers[obj.orderId])
                  : "Time Over"
                : obj.status === "pending"
                ? "--"
                : "Ready",

              <Button
                onClick={() => {
                  setModalOrder(obj);
                  setShowModal(true);
                }}
                className="bg-primaryColor"
              >
                <FaEye />
              </Button>,
            ])}
          />
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {orders.map((order, index) => (
              <div key={index} className="p-6 bg-secondaryColor rounded-lg ">
                <div className="text-2xl font-bold text-black mb-2">
                  {order.name}
                </div>

                <div className="text-sm text-gray-700 mb-2">
                  <span className="font-semibold">Table:</span>{" "}
                  {order.tableName}
                </div>
                {userData.userType === "admin" && order.chefId &&(
  <div className="text-sm text-gray-700 mb-2">
    <span className="font-semibold">Chef:</span>{" "}
    {order.chefId
      ? chefNames[order.chefId]
        ? chefNames[order.chefId].includes(" ")
          ? chefNames[order.chefId].split(" ")[0]
          : chefNames[order.chefId]
        : "Chef Not Found"
      : "-"}
  </div>
)}

                <div className="text-sm text-gray-700 mb-4">
                  <span className="font-semibold">Total Amount:</span>{" "}
                  {getCurrencySymbol(order.currency)}
                  {order.totalAmount}
                </div>

                <div className="flex items-center mb-4">
                  <div
                    className={`${
                      order.status === "ready"
                        ? "bg-green-600"
                        : order.status === "pending"
                        ? "bg-red-600"
                        : order.status === "preparing"
                        ? "bg-blue-600"
                        : "bg-gray-600"
                    } text-white font-bold px-4 py-1 rounded-full`}
                  >
                    {order.status}
                  </div>
                  {order.status === "preparing" && (
                    <div className="ml-4 text-gray-600">
                      <span className="text-sm font-semibold">Time Left: </span>
                      <span className="font-bold ">
                        {timers[order.orderId] > 0
                          ? formatTime(timers[order.orderId])
                          : "Time Over"}
                      </span>
                    </div>
                  )}
                </div>

                <Button
                  onClick={() => {
                    setModalOrder(order);
                    setShowModal(true);
                  }}
                  className="w-full bg-primaryColor"
                >
                  <div className="flex items-center justify-center space-x-2">
                    <FaEye />
                    <span>View Details</span>
                  </div>
                </Button>
              </div>
            ))}
          </div>
        )}
      </div>
      <OrderModal
        showModal={showModal}
        setShowModal={setShowModal}
        order={modalOrder}
        setOrder={setOrders}
        fetchOrders={getOrders}
      />
    </>
  );
};

export default Orders;
