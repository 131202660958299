import { Sidebar } from "flowbite-react";
import { MdFastfood } from "react-icons/md";
import { IoMdPersonAdd } from "react-icons/io";
import { Link } from "react-router-dom";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { useContext } from "react";
import { Context } from "../../../Context";
import { CgProfile } from "react-icons/cg";
const routes = [
  { path: 'orders', label: 'Orders', icon: MdFastfood, allowedUserTypes: ['member', 'admin', 'chef','waiter'] },
  { path: 'addstaff', label: 'Add Staff', icon: IoMdPersonAdd, allowedUserTypes: ['admin'] },
  { path: 'item', label: 'ADD Product', icon: MdOutlineProductionQuantityLimits, allowedUserTypes: ['admin', 'chef'] },
  // { path: 'profile', label: 'Profile', icon: CgProfile, allowedUserTypes: ['member', 'admin', 'chef','waiter'] },
]

const SideBar = () => {
  const { userData } = useContext(Context); 
  const userType = userData?.userType; 

  return (
    <div className="flex flex-col lg:flex-row lg:min-h-screen">
      <Sidebar className="lg:block hidden border-r-2">
        <Sidebar.Items>
          <Sidebar.ItemGroup>
            {routes.map((item, index) => {
              
              if (item.allowedUserTypes.includes(userType)) {
                return (
                  <Sidebar.Item key={index} icon={item.icon}>
                    <Link to={`/dashboard/${item.path}`}>{item.label}</Link>
                  </Sidebar.Item>
                );
              }
              return null; 
            })}
          </Sidebar.ItemGroup>
        </Sidebar.Items>
      </Sidebar>

   
      <div className="lg:hidden fixed bottom-0 left-0 w-full bg-white z-10 shadow-md">
        <div className="flex justify-around py-2">
          {routes.map((item, index) => {
          
            if (item.allowedUserTypes.includes(userType)) {
              return (
                <Link key={index} to={`/dashboard/${item.path}`} className="flex flex-col items-center">
                  <item.icon className="text-xl" />
                  <span className="text-xs">{item.label}</span>
                </Link>
              );
            }
            return null; 
          })}
        </div>
      </div>
    </div>
  );
}

export default SideBar;
