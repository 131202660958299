import { useContext } from "react";
import { Context } from "../../../Context";

const Hero = () => {
  const { tagLine1, tagLine2, heroImage } = useContext(Context).institutionData;

  return (
    <section className='flex flex-wrap-reverse gap-y-16 gap-x-32 items-center justify-center bg-secondaryColor py-32'>
      <div>
        <h2 className='text-6xl text-left'>{tagLine1}</h2>
        <p className='max-w-[40rem] font-montserrat my-8'>{tagLine2}</p>
        <button className='p-4 bg-primaryColor rounded-xl text-white font-montserrat font-bold'>Explore</button>
      </div>
      <img src={heroImage} alt={heroImage} width={320} />
    </section>
  )
}

export default Hero;