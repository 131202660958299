import React from 'react'

const FrontProduct = ({ productTagline,
    handleSearch,
    handleSearchInputChange,
    searchTerm,
    suggestions,
    setSearchTerm,
    setSuggestions}) => {
  return (
    <div className="h-[70vh] flex flex-col py-10 justify-center bg-secondaryColor">
    <div className="text-center mt-10 px-2 max-w-lg mx-auto">
      <h1 className="text-black font-bold text-3xl mb-4">Products</h1>
      <p className="text-black">{productTagline}</p>
      <div className="mt-10">
        <form className="max-w-lg mx-auto" onSubmit={handleSearch}>
          <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Search Products..."
              onChange={handleSearchInputChange}
              value={searchTerm}
            />
            {suggestions.length > 0 && (
              <ul className="absolute z-10 bg-white border border-gray-300 rounded-lg mt-1 w-full max-h-40 overflow-y-auto shadow-lg">
                {suggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      setSearchTerm(suggestion);
                      setSuggestions([]);
                    }}
                    className="cursor-pointer p-2 hover:bg-gray-200"
                  >
                    {suggestion}
                  </li>
                ))}
              </ul>
            )}

            <button
              type="submit"
              className="text-white absolute right-2.5 bottom-2.5 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-4 py-2 bg-primaryColor"
            >
              Search
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  )
}

export default FrontProduct