import React, { useState, useEffect, useContext } from "react";
import { API } from "aws-amplify";
import { PaginatedTable } from "../../../../common/DataDisplay";
import { Button } from "flowbite-react";
import { Context } from "../../../../Context";
import ItemModal from "./ItemModal";
import currencies from "../../../../common/Inputs/currencies.json";
import { getCurrencySymbol } from "../../../../common/Inputs";
import { MdDeleteForever } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
const Item = () => {
  const [items, setItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const { institutionData,setLoader } = useContext(Context);
  const [categories, setCategories] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    const handleItemGet = async () => {
      if (!institutionData || !institutionData.institution) {
        console.log("Institution data is missing.");
        return;
      }

      setLoader(true);
      try {
        const itemsData = await API.get(
          "main",
          `/any/list-items/${institutionData.institution}`,
          {}
        );

        const updatedItems = itemsData
          .filter((item) => item.itemId && item.itemId !== "undefined")
          .map((item) => ({
            ...item,
            outOfStock: item.outOfStock ?? false,
          }));

        console.log("Updated items:", updatedItems);

        setItems(updatedItems);
        const uniqueCategories = [
          ...new Set(updatedItems.map((item) => item.category).filter(Boolean)),
        ];
        console.log("Unique categories:", uniqueCategories);
  
        setCategories(uniqueCategories);
      } catch (error) {
        console.error("Error fetching items:", error);
      } finally {
        setLoader(false);
      }
    };

  
    handleItemGet();
  }, [institutionData]);

  const handleToggleStock = async (itemId) => {
    console.log("Toggling stock for item ID:", itemId);
    if (!itemId) {
      console.error("Invalid itemId:", itemId);
      return;
    }

    const currentItem = items.find((item) => item.itemId === itemId);

    if (!currentItem) {
      console.error("Item not found for id:", itemId);
      return;
    }

    const updatedItem = {
      ...currentItem,
      outOfStock:
        currentItem.outOfStock !== undefined ? !currentItem.outOfStock : true,
    };

    setItems((prevItems) =>
      prevItems.map((item) => (item.itemId === itemId ? updatedItem : item))
    );

    try {
      await API.put(
        "main",
        `/admin/update-item/${institutionData.institution}/${itemId}`,
        {
          body: updatedItem,
        }
      );
      console.log(`Stock status successfully updated for item ${itemId}`);
    } catch (error) {
      console.error("Failed to update stock status:", error);

      setItems((prevItems) =>
        prevItems.map((item) => (item.itemId === itemId ? currentItem : item))
      );
    }
  };

  const handleEdit = (item) => {
    setCurrentItem(item);
    setIsEditMode(true);
    setShowModal(true);
  };

  const handleAdd = () => {
    setCurrentItem(null);
    setIsEditMode(false);
    setShowModal(true);
  };

  const handleSave = async (itemData) => {
    if (!itemData.itemName || itemData.price <= 0 || !itemData.category) {
      alert("Please fill in all required fields: itemName, price, and category.");
    
        setShowModal(false);
      return; 
    }
    if (isEditMode) {
      console.log("Saving changes for item:", itemData);
      try {
        await API.put(
          "main",
          `/admin/update-item/${institutionData.institution}/${currentItem.itemId}`,
          {
            body: itemData,
          }
        );
        console.log("Item updated successfully");
        const itemsData = await API.get(
          "main",
          `/any/list-items/${institutionData.institution}`,
          {}
        );

        const updatedItems = itemsData
          .filter((item) => item.itemId && item.itemId !== "undefined")
          .map((item) => ({
            ...item,
            outOfStock: item.outOfStock ?? false,
          }));

        console.log("Updated items:", updatedItems);

        setItems(updatedItems);
        setShowModal(false);
      } catch (error) {
        console.error("Error updating item:", error);
      }
    } else {
      console.log("Adding new item:", itemData);
      try {
        await API.post(
          "main",
          `/admin/create-item/${institutionData.institution}`,
          {
            body: itemData,
          }
        );
        console.log("Item added successfully");
        const itemsData = await API.get(
          "main",
          `/any/list-items/${institutionData.institution}`,
          {}
        );

        const updatedItems = itemsData
          .filter((item) => item.itemId && item.itemId !== "undefined")
          .map((item) => ({
            ...item,
            outOfStock: item.outOfStock ?? false,
          }));

        console.log("Updated items:", updatedItems);

        setItems(updatedItems);
        setShowModal(false);
      } catch (error) {
        console.error("Error adding item:", error);
      }
    }
  };
  const handleDelete = async (itemId) => {
    if (!itemId) {
      console.error('Invalid itemId:', itemId);
      return;
    }
  
    const confirmDelete = window.confirm('Are you sure you want to delete this item?');
    if (!confirmDelete) return;
  
    setLoader(true); 
    try {
      await API.del('main', `/admin/delete-item/${institutionData.institution}/${itemId}`);
      console.log('Item deleted successfully');
      
 
      const itemsData = await API.get(
        'main',
        `/any/list-items/${institutionData.institution}`,
        {}
      );
  
      const updatedItems = itemsData
        .filter((item) => item.itemId && item.itemId !== "undefined")
        .map((item) => ({
          ...item,
          outOfStock: item.outOfStock ?? false,
        }));
  
      setItems(updatedItems); 
    } catch (error) {
      console.error('Error deleting item:', error);
    } finally {
      setLoader(false); 
    }
  };
  
  const head = [
    "Item Name",
    "Price",
    "Category",
    "Veg/Non-Veg",
    "Stock",
    ""
     ];

  return (
    <div className="w-full p-8 rounded-lg">
      <h1 className="text-2xl font-bold mb-4 text-gray-800">
        Items Management
      </h1>
      <Button onClick={handleAdd} className="mb-4 bg-primaryColor">
        Add Item
      </Button>
      {screenWidth > 1025 ? (
      <PaginatedTable
        head={head}
        data={items.map((item) => [
          item.itemName,
          `${getCurrencySymbol(item.currency)}${item.price.toFixed(2)}`,
          item.category,
            item.veg ? "Veg" : "Non-Veg",
          <div className="flex flex-col items-start gap-1 ">
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={!item.outOfStock}
                onChange={() => {
                  if (item.itemId) {
                    handleToggleStock(item.itemId);
                  } else {
                    console.error("Item ID is missing");
                  }
                }}
              />
              <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full dark:bg-gray-700 peer-checked:bg-blue-600">
                <div className="absolute top-[2px] left-[2px] w-5 h-5 bg-white rounded-full transition-transform peer-checked:translate-x-5"></div>
              </div>
            </label>
            <span
              className={`text-sm font-semibold ${
                item.outOfStock ? "text-red-500" : "text-green-500"
              }`}
            >
              {item.outOfStock ? "Out of Stock" : "In Stock"}
            </span>
          </div>,
          <div className=" gap-2 justify-between max478:mt-4">
            <div className="flex  gap-2">

            <div
              
              onClick={() => handleEdit(item)}
              className="text-primaryColor text-2xl"
            >
              <FaEdit />
            </div>
            <div
             
              onClick={() => handleDelete(item.itemId)}
              className="text-red-600 text-2xl"
            >
             <MdDeleteForever />
            </div>
            </div>
          </div>
        ])}
        
        className="bg-white shadow rounded-lg"
      />
    ) : (
<>
{items.map((item) => (
          <div
            key={item.itemId}
            className="mb-4 p-4 bg-secondaryColor shadow rounded-lg flex flex-col gap-4"
          >
            <div className="flex justify-between">
              <h2 className="text-lg font-semibold">{item.itemName}</h2>
              <div className="flex gap-2">
                <FaEdit
                  className="text-primaryColor text-xl cursor-pointer"
                  onClick={() => handleEdit(item)}
                />
                <MdDeleteForever
                  className="text-red-600 text-xl cursor-pointer"
                  onClick={() => handleDelete(item.itemId)}
                />
              </div>
            </div>
            <p className="text-gray-600">
              Price:{" "}
              <span className="font-semibold">
                {getCurrencySymbol(item.currency)}
                {item.price.toFixed(2)}
              </span>
            </p>
            <p className="text-gray-600">
  Type:{" "}
  <span className={`font-semibold ${item.veg ? "text-green-600" : "text-red-600"}`}>
    {item.veg ? "Veg" : "Non-Veg"}
  </span>
</p>

            <p className="text-gray-600">
              Category: <span className="font-semibold">{item.category}</span>
            </p>
            <div className="flex justify-between items-center">
              <span
                className={`text-sm font-semibold ${
                  item.outOfStock ? "text-red-500" : "text-green-500"
                }`}
              >
                {item.outOfStock ? "Out of Stock" : "In Stock"}
              </span>
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={!item.outOfStock}
                  onChange={() =>
                    item.itemId && handleToggleStock(item.itemId)
                  }
                />
                <div className="relative w-11 h-6 bg-gray-200 rounded-full peer-checked:bg-blue-600">
                  <div className="absolute top-[2px] left-[2px] w-5 h-5 bg-white rounded-full transition-transform peer-checked:translate-x-5"></div>
                </div>
              </label>
            </div>
          </div>
        ))}
</>

    )}
      <ItemModal
        showModal={showModal}
        setShowModal={setShowModal}
        currentItem={currentItem}
        isEditMode={isEditMode}
        handleSave={handleSave}
        setCategories={setCategories}
        categories={categories}
        currencies={currencies}
        institution={institutionData.institution}
      />
    </div>
  );
};

export default Item;
