import { Modal } from "flowbite-react";
import { useContext, useEffect, useState } from "react";
import { API } from "aws-amplify";
import { toast } from "react-toastify";
import { FaTimes, FaCheckCircle, FaClock } from "react-icons/fa";
import { Context } from "../../../../../Context";
import { getCurrencySymbol } from "../../../../../common/Inputs";
const OrderModal = ({ showModal, setShowModal, order, setOrder, fetchOrders }) => {
  const { userData, institutionData } = useContext(Context);
  const { institution } = institutionData;
  const [minutes, setMinutes] = useState("");
  const [timeRemaining, setTimeRemaining] = useState(0);

  const handleTakeOrder = async () => {
    if (!minutes || parseInt(minutes) <= 0) {
      toast.warning("Please enter a valid time greater than 0 minutes.");
      return;
    }

    const updatedOrder = {
      ...order,
      status: "preparing",
      deliveryTime: Date.now() + parseInt(minutes) * 60 * 1000,
    };

    try {
      await API.put("main", `/chef/update-order/${institution}`, { body: updatedOrder });
      fetchOrders();
      toast.success("Order status updated to 'preparing'!");
      closeModal();
    } catch (e) {
      console.error("Error in Take Order:", e);
      toast.error("Failed to update order status.");
    }
  };


  const handleFinishOrder = async () => {
    

    const updatedOrder = { ...order, status: "ready", finishedTime: Date.now() };

    try {
      await API.put("main", `/chef/update-order/${institution}`, { body: updatedOrder });
      fetchOrders();
      closeModal();
      toast.success("Order marked as 'ready'!");
    } catch (e) {
      console.error("Error in Finish Order:", e);
      toast.error("Failed to mark order as finished.");
    }
  };


  const calculateTimeRemaining = (targetTime) => {
    const now = Date.now();
    const timeDiff = targetTime - now;
    return Math.max(Math.ceil(timeDiff / 60000), 0); 
  };

  useEffect(() => {
    if (order.deliveryTime && order.status === "preparing") {
      const intervalId = setInterval(() => {
        const newTimeRemaining = calculateTimeRemaining(order.deliveryTime);
        setTimeRemaining(newTimeRemaining);
  
        if (newTimeRemaining <= 0) {
          clearInterval(intervalId);
          toast.info("Preparation time is over. Please mark the order as ready.");
        }
      }, 60000);
  
      return () => clearInterval(intervalId);
    }
  }, [order.deliveryTime]);
  
  const closeModal = () => {
    setShowModal(false);
    setMinutes("");
  };

  return (
    <Modal show={showModal} onClose={closeModal}  className="transition-all transform duration-300 ease-in-out "size="md">
      <Modal.Header closeButton>
        <div className="flex justify-between items-center w-full">
          <h1 className="text-xl font-semibold text-gray-800">Order Details</h1>
        </div>
      </Modal.Header>
      <Modal.Body className="space-y-2 bg-white shadow-lg rounded-lg p-6">
        <p className="text-lg text-gray-700">
          <span className="font-semibold">OrderID: </span>
          {order.orderId}
        </p>
        <p className="text-lg text-gray-700">
          <span className="font-semibold">Total Amount: </span>
          <span className="text-green-500">{getCurrencySymbol(order.currency)}{order.totalAmount}</span>
        </p>

     
        <h3 className="text-lg font-semibold text-gray-800">Items:</h3>
        <div className="max-h-32 overflow-y-auto">
          <ul className="space-y-3 px-2">
            {order.items?.map((item) => (
              <li
                key={item.itemId}
                className="flex justify-between items-center p-4 bg-gray-100 rounded-lg shadow-sm transition-all hover:bg-gray-200"
              >
                <span className="font-medium text-gray-800">{item.itemName}</span>
                <span className="text-gray-600">x{item.quantity}</span>
              </li>
            ))}
          </ul>
        </div>

     
        <p className="text-lg text-gray-700">
          <span className="font-semibold">Status: </span>
          <span
            className={`font-semibold ${
              order.status === "pending"
                ? "text-yellow-500"
                : order.status === "preparing"
                ? "text-blue-500"
                : "text-green-500"
            }`}
          >
            {order.status}
          </span>
        </p>

    
        {userData?.userType === "chef" && (
  <>
    {order.status === "preparing" && (
      <p className="text-lg text-gray-700">
        <span className="font-semibold">Time Remaining: </span>
        <span className="text-red-500">{timeRemaining}</span> minute
        {timeRemaining !== 1 ? "s" : ""}
      </p>
    )}

    {order.status === "pending" && (
      <div className="mt-4 space-y-2">
        <label className="block text-sm font-semibold text-gray-800">
          Preparation Time (minutes)
        </label>
        <input
          type="number"
          value={minutes}
          onChange={(e) => setMinutes(e.target.value)}
          placeholder="e.g., 15"
          className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all"
        />
        <button
          className="w-full bg-green-600 text-white py-2 px-4 rounded-lg hover:bg-green-700 transition-all"
          onClick={handleTakeOrder}
        >
          <FaCheckCircle className="inline-block mr-2" />
          Start Preparing
        </button>
      </div>
    )}

    {order.status === "preparing" && (
      <div className="mt-4">
        <button
          className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-all"
          onClick={handleFinishOrder}
        >
          <FaClock className="inline-block mr-2" />
          Confirm to Mark as Ready
        </button>
      </div>
    )}
  </>
)}

      </Modal.Body>
    </Modal>
  );
};

export default OrderModal;
