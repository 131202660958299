import React, { useContext } from 'react';
import Product from './Product';
import { Context } from "../../../Context";
import Header from '../../../components/Header';

const index = () => {
  const { items } = useContext(Context);

  return (
    <div>
      <Header />
      <Product items={items}/>
    </div>
  )
}
export default index