import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; 
import { Context } from "../../../Context";
import { getCurrencySymbol } from "../../../common/Inputs";
const Categories = () => {
  const { items } = useContext(Context);
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!items || items.length === 0) return;
    const filteredItems = items.filter(item => !item.outOfStock);
    const groupedCategories = filteredItems.reduce((acc, item) => {
      if (!item.category) return acc;
      if (!acc[item.category]) {
        acc[item.category] = [];
      }
      acc[item.category].push(item);
      return acc;
    }, {});

    const sortedCategories = Object.entries(groupedCategories).sort(
      (a, b) => b[1].length - a[1].length
    );

    const topCategories = sortedCategories.slice(0, 2);

    let maxItemsToShow = Math.min(
      topCategories[0]?.[1]?.length || 0,
      topCategories[1]?.[1]?.length || 5
    );
    maxItemsToShow = Math.min(maxItemsToShow, 5);

    const selectedCategories = topCategories.map(([key, value]) => ({
      category: key,
      items: value.slice(0, maxItemsToShow),
    }));

    setCategories(selectedCategories);
  }, [items]);

  return (
    <section>
      <div>
        <h1 className="text-4xl font-extrabold text-center text-gray-800 mb-8">
          Categories
        </h1>

      
        {categories.length === 0 ? (
          <div className="text-center">
            <p className="text-lg text-gray-700">No categories available.</p>
          </div>
        ) : (
          <div>
            {categories.length === 1 ? (
              <div className="flex justify-center flex-col items-center">
                <div
                  key={categories[0].category}
                  className="  w-full max-w-md"
                >
                  <h2 className="text-2xl font-bold text-gray-700 mb-6 border-b-2 border-gray-200 pb-2 text-center">
                    {categories[0].category
                      ? categories[0].category[0].toUpperCase() + categories[0].category.slice(1)
                      : "Unknown Category"}
                  </h2>

                  <div className="grid gap-4">
                    {categories[0].items.map((item) => (
                      <div
                      key={item.itemId || Math.random()}
                      className="relative flex items-center bg-secondaryColor rounded-lg p-4 shadow-md hover:shadow-lg transition-all duration-300"
                    >
                   
                      {item.veg !== undefined && (
                        <div
                          className={`absolute top-2 right-2 flex items-center justify-center w-20 h-8 border rounded-md ${
                            item.veg ? "bg-green-100 border-green-500" : "bg-red-100 border-red-500"
                          }`}
                        >
                          <div
                            className={`w-3 h-3 rounded-full ${
                              item.veg ? "bg-green-500" : "bg-red-500"
                            }`}
                          ></div>
                          <span
                            className={`ml-2 text-[10px] font-bold ${
                              item.veg ? "text-green-700" : "text-red-700"
                            }`}
                          >
                            {item.veg ? "VEG" : "NON-VEG"}
                          </span>
                        </div>
                      )}
                  
                      <div className="ml-4">
                        <h3 className="font-semibold text-lg text-gray-800">
                          {item.itemName
                            ? item.itemName[0].toUpperCase() + item.itemName.slice(1)
                            : "Unnamed Item"}
                        </h3>
                        <p className="text-gray-600 text-sm">
                          {item.price
                            ? `${getCurrencySymbol(item.currency) || ""}${item.price}`
                            : "Price Not Available"}
                        </p>
                      </div>
                    </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 lg:gap-20">
                {categories.map(({ category, items }) => (
                  <div
                    key={category}
                    className=""
                  >
                    <h2 className="text-2xl font-bold text-gray-700 mb-6 border-b-2 border-gray-200 pb-2">
                      {category ? category[0].toUpperCase() + category.slice(1) : "Unknown Category"}
                    </h2>

                    <div className="grid gap-4">
                      {items.map((item) => (
                        <div
                        key={item.itemId || Math.random()}
                        className="relative flex items-center bg-secondaryColor rounded-lg p-4 shadow-md hover:shadow-lg transition-all duration-300"
                      >
                     
                        {item.veg !== undefined && (
                          <div
                            className={`absolute top-2 right-2 flex items-center justify-center w-20 h-8 border rounded-md ${
                              item.veg ? "bg-green-100 border-green-500" : "bg-red-100 border-red-500"
                            }`}
                          >
                            <div
                              className={`w-3 h-3 rounded-full ${
                                item.veg ? "bg-green-500" : "bg-red-500"
                              }`}
                            ></div>
                            <span
                              className={`ml-2 text-[10px] font-bold ${
                                item.veg ? "text-green-700" : "text-red-700"
                              }`}
                            >
                              {item.veg ? "VEG" : "NON-VEG"}
                            </span>
                          </div>
                        )}
                    
                        <div className="ml-4">
                          <h3 className="font-semibold text-lg text-gray-800">
                            {item.itemName
                              ? item.itemName[0].toUpperCase() + item.itemName.slice(1)
                              : "Unnamed Item"}
                          </h3>
                          <p className="text-gray-600 text-sm">
                            {item.price
                              ? `${getCurrencySymbol(item.currency) || ""}${item.price}`
                              : "Price Not Available"}
                          </p>
                        </div>
                      </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        <div className="flex justify-center mt-10">
          <button
            className="bg-primaryColor text-white text-lg font-semibold px-8 py-3 rounded-full shadow-lg transition-all duration-300"
            onClick={() => navigate("/product")}
          >
            Explore More
          </button>
        </div>
      </div>
    </section>
  );
};

export default Categories;
