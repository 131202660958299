import RoutesContainer from "./routes";
import LoaderProvider from "./components/LoaderProvider";

function App() {
  return (
    <LoaderProvider>
      <RoutesContainer />
    </LoaderProvider>
  );
}

export default App;