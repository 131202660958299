import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../../Context';
import { API } from 'aws-amplify';
import {getCurrencySymbol} from "../../../common/Inputs";
const OrderPopup = ({ isPopupOpen, setIsPopupOpen }) => {
  const { institutionData } = useContext(Context);
  const [orderDetails, setOrderDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [remainingTime, setRemainingTime] = useState({});
  
  const calculateRemainingTime = (deliveryTime) => {
    const timeDiff = new Date(deliveryTime) - new Date();
    if (timeDiff <= 0) return { timeLeft: "Delivered", secondsLeft: 0 };
    
    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
    
    return { timeLeft: `${hours}h ${minutes}m ${seconds}s`, secondsLeft: timeDiff / 1000 };
  };
  useEffect(() => {
    const fetchOrderDetails = async () => {
      const orderId = localStorage.getItem("orderId");
      if (orderId) {
        try {
          const response = await API.get('main', `/any/get-order/${institutionData.institution}`, {
            queryStringParameters: { orderId }
          });
          console.log("Order details fetched successfully:", response);
          
          setOrderDetails(response.orders || []);
        } catch (error) {
          console.error("Failed to fetch order details:", error.response ? error.response.data : error.message);
        }
      } else {
        console.warn("No order ID found in local storage.");
      }
      setLoading(false);
    };

    if (isPopupOpen) {
      fetchOrderDetails();
    }
  }, [isPopupOpen, institutionData]);
  useEffect(() => {
    if (orderDetails.length > 0) {
      const intervalId = setInterval(() => {
        setOrderDetails(prevOrders =>
          prevOrders.map(order => {
            if (order.deliveryTime) {
              const { timeLeft, secondsLeft } = calculateRemainingTime(order.deliveryTime);
              if (secondsLeft > 0) {
                return { ...order, remainingTime: timeLeft };
              } else {
                return { ...order, remainingTime: "Delivered", status: "ready" };
              }
            }
            return order;
          })
        );
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [orderDetails]);
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full text-left">
        <h2 className="text-2xl font-bold mb-4 text-center">Order Details</h2>
        {loading ? (
          <p className="text-center">Loading...</p>
        ) : orderDetails.length > 0 ? (
          orderDetails.map((order, index) => (
            <div key={index} className="mb-4 border-b pb-4">
              <h3 className="text-lg font-semibold">Order ID: <span className="text-primaryColor">{order.orderId}</span></h3>
              <p className="mb-2">Total Amount: <span className="font-bold">{getCurrencySymbol(order.currency) }{order.totalAmount}</span></p>
              <p className="mb-1">Table Name: {order.tableName}</p>
              <p className="mb-1">Name: {order.name}</p>
              <h4 className="font-bold mb-2">Items:</h4>
              <ul className="list-disc ml-5 max-h-60 overflow-y-auto">
                {order.items.map((item) => (
                  <li key={item.itemId} className="mb-1">
                    <span>{item.itemName} (x{item.quantity}): {getCurrencySymbol(order.currency) }{item.price}</span>
                  </li>
                ))}
              </ul>
              <p className="text-sm text-gray-500">Created At: {new Date(order.createdAt).toLocaleString()}</p>
              {order.remainingTime && (
                <div className="mt-4">
                  <div className="flex items-center justify-between">
                    <span className="font-semibold">Time Left:</span>
                    <span className={`text-lg font-bold ${order.remainingTime === "Delivered" ? 'text-green-500' : 'text-red-500'}`}>
                      {order.remainingTime}
                    </span>
                  </div>
                  {order.remainingTime !== "Delivered" && (
                    <div className="w-full bg-gray-200 rounded-full mt-2">
                      <div
                        className="h-2 rounded-full bg-primaryColor"
                        style={{
                          width: `${(order.remainingTime.secondsLeft / (new Date(order.deliveryTime) - new Date())) * 100}%`,
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
              {order.status && (
                <div className={`${
                  order.status === 'ready'
                    ? 'text-green-600'
                    : order.status === 'pending'
                    ? 'text-red-600'
                    : order.status === 'preparing'
                    ? 'text-blue-600 '
                    : 'text-gray-600'
                } p-1 mt-1`}>
                  <span>
                    Status: {order.status.charAt(0).toUpperCase() + order.status.slice(1)}
                  </span>
                </div>
              )}
            </div>
          ))
        ) : (
          <p className="mb-4 text-center">No order details available.</p>
        )}
        <div className="flex justify-center mt-4">
          <button
            className="px-6 py-2 text-white bg-primaryColor rounded-lg transition duration-200 hover:bg-opacity-80"
            onClick={() => setIsPopupOpen(false)}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderPopup;
