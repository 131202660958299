import React from 'react'
import Item from './Item'
const index = () => {
  return (
    <div className='bg-gray-100 py-2'>
        <Item />
    </div>
  )
}

export default index