import React, {createContext, useEffect, useState} from 'react';
import { institutionData as data } from '../utils/constants';
import {API, Auth} from "aws-amplify";

const Context = createContext({});

const Provider = ({ children }) => {
  const [userData, setUserData] = useState({});
  const [institutionData, setInstitutionData] = useState(data);
  const [items, setItems] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const init = async () => {
      setLoader(true);

      document.body.style.setProperty('--primary-color', institutionData.PrimaryColor);
      document.body.style.setProperty('--secondary-color', institutionData.SecondaryColor);
      document.body.style.setProperty('--light-primary-color', institutionData.LightPrimaryColor);
      document.body.style.setProperty('--secondary-color', institutionData.SecondaryColor);

      try {
        const itemsData = await API.get(
          'main',
          `/any/list-items/${ institutionData.institution }`,
          {}
        );
        setItems(itemsData);
      } catch (e) {
        console.log(e);
      }

      try {
        await Auth.currentAuthenticatedUser();
        const userData = await API.get(
          'main',
          `/user/get-userdata/${ institutionData.institution }`,
          {}
        );
        setUserData(userData);
      } catch (e) {
        console.log(e);
      } finally {
        setLoader(false);
      }
    };

    init();
  }, []);

  return (
    <Context.Provider value={{
      userData, setUserData,
      institutionData, setInstitutionData,
      items, setItems,
      loader, setLoader
    }}>
      {children}
    </Context.Provider>
  );
};

export { Context, Provider };
