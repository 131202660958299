import {Routes, Route} from 'react-router-dom';
import Error from '../pages/Error';
import Auth from "../pages/Auth";
import Logout from "../pages/Auth/Logout";
import Home from '../pages/public/Home';
import Product from "../pages/public/Product";
import Dashboard from "../pages/private/Dashboard";
import Orders from "../pages/private/Dashboard/Orders";
// import AddStaff from '../pages/private/Dashboard/AddStaff/CreatePopup';
import Item from '../pages/private/Dashboard/Item';
import Stafflist from '../pages/private/Dashboard/AddStaff';
// import Profile from "../pages/private/Dashboard/Profile/Profile.jsx"
import QRManage from '../pages/private/Dashboard/QRManage/QRManage.jsx';
const RoutesContainer = () => {
  return (
    <Routes>
      <Route path='/' element={ <Home /> } />
      <Route path='/auth' element={ <Auth /> } />
      <Route path='/dashboard' element={ <Dashboard /> }>
        <Route path='orders' element={ <Orders /> } />
        <Route path='' element={ <Orders /> } />
        <Route path='addstaff' element={ <Stafflist /> } />
        <Route path='item' element={ <Item /> } />
        {/* <Route path='profile' element={ <Profile /> } /> */}
        <Route path='qrmanage' element={ <QRManage /> } />
      </Route>
      <Route path='/logout' element={ <Logout /> } />
      <Route path='/product' element={ <Product /> } />
      <Route path='*' element={ <Error /> } />
    </Routes>
  )
}

export default RoutesContainer;