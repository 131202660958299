import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../Context";
import { useNavigate } from "react-router-dom";
import { Select } from "flowbite-react";
import ProductCard from "./ProductCard";
import FilterPopup from "./FilterPopup.jsx";
import FrontProduct from "./FrontProduct.jsx";
import OrderPopup from "./OrderPopup.jsx";
import ViewCartPopUp from "./ViewCartPopUp.jsx";
import { FiChevronDown } from "react-icons/fi";
const Product = ({ items }) => {
  const { PrimaryColor, productTagline } = useContext(Context).institutionData;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { userData } = useContext(Context);
  const [isCartPopupOpen, setIsCartPopupOpen] = useState(false);

  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState("");
  const [cartCount, setCartCount] = useState(0);
  const [vegFilter, setVegFilter] = useState("All");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleViewCartClick = () => {
    setIsCartPopupOpen(true);
  };

  useEffect(() => {
    const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    setCartCount(cartItems.length);
  }, [ refreshKey]);
  useEffect(() => {
    const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    const outOfStockItemIds = items
      .filter((item) => item.outOfStock)
      .map((item) => item.itemId);
    const updatedCart = cartItems.filter(
      (cartItem) => !outOfStockItemIds.includes(cartItem.itemId)
    );

    localStorage.setItem("cartItems", JSON.stringify(updatedCart));
    setCartCount(updatedCart.length);
  }, [items, refreshKey]);

  const handleOrderPlaced = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };
  const handleSearch = (e) => {
    e.preventDefault();
    setIsSearchClicked(true);
  };
  const shuffleItems = (items) => {
    return items.sort(() => Math.random() - 0.5);
  };
  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value) {
      const filteredSuggestions = items
        .map((item) => item.itemName)
        .filter((name) => name.toLowerCase().includes(value.toLowerCase()));
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const filteredItems = items.filter(
    (item) =>
      item.itemName.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (categoryFilter ? item.category === categoryFilter : true) &&
      (vegFilter === "All" || (vegFilter === "Veg" ? item.veg : !item.veg))
  );

  const categories = [...new Set(items.map((item) => item.category))];
  const sortedItems = filteredItems.sort((a, b) => {
    if (sortOrder === "lowToHigh") {
      return a.price - b.price;
    } else if (sortOrder === "highToLow") {
      return b.price - a.price;
    }
    return 0;
  });

  const groupedItems = categories
    .map((category) => ({
      category,
      items: filteredItems.filter((item) => item.category === category),
    }))
    .filter((group) => group.items.length > 0);

  const applyFilters = () => {
    setIsModalOpen(false);
    setIsSearchClicked(true);
  };

  const handleAddToCart = (item) => {
    const storedCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];

    const existingItem = storedCartItems.find(
      (cartItem) => cartItem.itemId === item.itemId
    );

    if (existingItem) {
      alert("Item is already in the cart.");
    } else {
      const updatedCartItems = [...storedCartItems, { ...item, quantity: 1 }];
      localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
      setCartCount(updatedCartItems.length);
    }
  };

  const isItemInCart = (itemId) => {
    const storedCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    return storedCartItems.some((cartItem) => cartItem.itemId === itemId);
  };
  const handleOrderButtonClick = () => {
    const orderId = localStorage.getItem("orderId");

    if (userData.cognitoId) {
      navigate("/dashboard");
    } else if (orderId) {
      setIsPopupOpen(true);
    }
  };
  const orderIdInStorage = localStorage.getItem("orderId");
  return (
    <>
      <FrontProduct
        productTagline={productTagline}
        handleSearch={handleSearch}
        handleSearchInputChange={handleSearchInputChange}
        searchTerm={searchTerm}
        suggestions={suggestions}
        setSearchTerm={setSearchTerm}
        setSuggestions={setSuggestions}
      />

      <div className="flex flex-col sm:flex-row justify-between mt-6 gap-4 px-4 sm:px-10">
        <div className="relative mt-4 text-white">
          <button
            className="p-2 px-8 text-white rounded-lg bg-primaryColor w-full flex justify-between items-center gap-4 max850:flex max850:justify-center"
            onClick={toggleDropdown}
          >
            {vegFilter}
            <FiChevronDown
              className={`transition-transform ${
                isDropdownOpen ? "rotate-180" : ""
              }`}
              size={20}
            />
          </button>
          {isDropdownOpen && (
            <ul className="absolute left-0 w-full bg-primaryColor rounded-lg mt-2 z-50">
              <li
                className="px-4 py-2 text-white cursor-pointer hover:bg-primaryColorLight"
                onClick={() => {
                  setVegFilter("All");
                  setIsDropdownOpen(false);
                }}
              >
                All
              </li>
              <li
                className="px-4 py-2 text-white cursor-pointer hover:bg-primaryColorLight"
                onClick={() => {
                  setVegFilter("Veg");
                  setIsDropdownOpen(false);
                }}
              >
                Veg
              </li>
              <li
                className="px-4 py-2 text-white cursor-pointer hover:bg-primaryColorLight"
                onClick={() => {
                  setVegFilter("NonVeg");
                  setIsDropdownOpen(false);
                }}
              >
                NonVeg
              </li>
            </ul>
          )}
        </div>

        <div className="flex flex-col sm:flex-row sm:items-start">
          <button
            className="p-2 text-white rounded-lg bg-primaryColor w-full sm:w-auto relative flex justify-center"
            onClick={handleViewCartClick}
          >
            View Cart
            <div className="relative max600:-mb-2 inline-block">
              <svg
                width="28"
                height="24"
                viewBox="0 0 48 40"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M37.721 25L43.265 11.14L41.367 10.6125L36.279 23.3333H17.721L10.721 5.83334H5V7.50001H9.279L15.929 24.1233L11.461 32.5H39V30.8333H14.539L17.65 25H37.721Z"
                  fill="white"
                />
                <path
                  d="M20 36.6667C21.1046 36.6667 22 35.9205 22 35C22 34.0795 21.1046 33.3333 20 33.3333C18.8954 33.3333 18 34.0795 18 35C18 35.9205 18.8954 36.6667 20 36.6667Z"
                  fill="white"
                />
                <path
                  d="M32 36.6667C33.1046 36.6667 34 35.9205 34 35C34 34.0795 33.1046 33.3333 32 33.3333C30.8954 33.3333 30 34.0795 30 35C30 35.9205 30.8954 36.6667 32 36.6667Z"
                  fill="white"
                />
              </svg>
              {cartCount >= 0 && (
                <span className="absolute top-[-2px] mb-3 right-[5px] text-white  rounded-full p-[2px] px-[5px] text-[10px] font-bold">
                  {cartCount}
                </span>
              )}
            </div>
          </button>
          {(userData.cognitoId || orderIdInStorage) && (
            <button
              className="p-2 text-white rounded-lg bg-primaryColor mt-2 sm:mt-0 sm:ml-2"
              onClick={handleOrderButtonClick}
            >
              Your Order
            </button>
          )}
        </div>
      </div>

      {isModalOpen && (
        <FilterPopup
          categories={categories}
          setCategoryFilter={setCategoryFilter}
          categoryFilter={categoryFilter}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          applyFilters={applyFilters}
          setIsModalOpen={setIsModalOpen}
        />
      )}
      {isPopupOpen && (
        <OrderPopup setIsPopupOpen={setIsPopupOpen} isPopupOpen={isPopupOpen} />
      )}
      {isCartPopupOpen && (
        <ViewCartPopUp
          isOpen={isCartPopupOpen}
          closePopup={() => setIsCartPopupOpen(false)}
          onOrderPlaced={handleOrderPlaced}
        />
      )}
      <ProductCard
        groupedItems={groupedItems}
        isSearchClicked={isSearchClicked}
        filteredItems={filteredItems}
        isItemInCart={isItemInCart}
        handleAddToCart={handleAddToCart}
        navigate={navigate}
        handleViewCartClick={handleViewCartClick}
      />
    </>
  );
};
export default Product;
