import {Avatar, Navbar} from "flowbite-react";
import {useContext} from "react";
import {Context} from "../../Context";
import {NavLink} from "react-router-dom";
import {defaultProfileImageUrl} from "../../utils/constants";

const Header = ({...props}) => {
  const { userData, institutionData } = useContext(Context);
  const { name, profileImageUrl } = userData;
  const { logoUrl } = institutionData;

  const content = [
    { label: 'HOME', path: '/' },
    { label: 'PRODUCT', path: '/product' },
    {
      label: name
        ? <Avatar img={ profileImageUrl || defaultProfileImageUrl } alt='Profile Image' rounded />
        : 'LOGIN',
      path: name
        ? '/dashboard/orders'
        : '/auth'
    },
  ]

  return (
    <Navbar border style={{
      zIndex: 50,
      position: 'sticky',
      top: 0,
      padding: '1rem',
      width: '100%',
    }} {...props}>
      <Navbar.Brand href="/">
        <img src={logoUrl} alt="Cafe Logo" />
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse className='font-roboto'>
        {content.map((item, index) => (
          <NavLink
            key={item.label}
            to={item.path}
            className={({ isActive }) =>
              'flex items-center text-lg ' +
              (isActive ? 'text-primaryColor'
                : 'text-stone-400 hover:text-primaryColor')
            }
          >
            {item.label}
          </NavLink>
        ))}
      </Navbar.Collapse>
    </Navbar>
  )
}

export default Header;



// import AuthBar from "./AuthBar";
// import NavBar from "./NavBar";
// import { defaultProfileImageUrl } from "../../utils/constants";
// import { useContext } from "react";
// import { Context } from "../../Context";
//
//
// const Header = () => {
//   const { isAuth, userData } = useContext(Context);
//   const { profileImageUrl, name, status } = userData;
//
//   const profileImage = (
//     <img
//       src={profileImageUrl || defaultProfileImageUrl}
//       alt="Profile"
//       width="30"
//       height="30"
//       className={`border-[3px] ${status === "Active" ? 'border-lighestPrimaryColor' : 'border-red-500'} rounded-full`}
//     />
//   );
//
//   const authBarContent = isAuth
//     ? [
//         { label: "Dashboard", path: "/dashboard" },
//         { label: profileImage, path: "/dashboard" },
//       ]
//     : [
//         { label: 'Login', path: '/auth' },
//         { label: process.env.REACT_APP_STAGE !== 'prod' && "Dev", path: "/auth" },
//       ];
//
//   let navBarContent = [
//     { label: "ABOUT US", path: "/aboutus" },
//     { label: name ? "" : "LOGIN", path: name ? "" : "/auth" },
//   ];
//
//   return (
//     <header className="z-50 h-[4.5rem]">
//       {/*<AuthBar content={authBarContent} />*/}
//       <NavBar content={navBarContent} />
//     </header>
//   );
// };
//
// export default Header;