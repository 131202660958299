import Header from "../../../components/Header";
import { Outlet } from "react-router-dom";
import SideBar from "./SideBar";

const Dashboard = () => {
  return (
    <>
      <Header/>
      <main className='grid grid-cols-[auto_1fr] h-[93vh]'>
        <SideBar/>
        <Outlet/>
      </main>
    </>

  )
}

export default Dashboard;