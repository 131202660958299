import React, { useState, useEffect } from 'react';
import { Modal, Button, TextInput, Label, Select, HelperText, FileInput } from 'flowbite-react';

const ItemModal = ({
  showModal,
  setShowModal,
  currentItem,
  isEditMode,
  handleSave,
  institution,
  categories,
  currencies
}) => {
  
  const [itemData, setItemData] = useState({
    institution,
    itemName: '',
    price: 0,
    veg: false,
    category: '',
    currency: 'INR',
    outOfStock: false,

  });
  const [descriptionError, setDescriptionError] = useState(false);
const [uploading, setUploading] = useState(false);
const [isOtherSelected, setIsOtherSelected] = useState(false);
  useEffect(() => {
    if (showModal) {
      setIsOtherSelected(false); 
    } 
    if (isEditMode && currentItem) {
      setItemData({
        institution,
        itemName: currentItem.itemName,
        price: currentItem.price,
        veg: currentItem.veg || false,
        category: currentItem.category || '',  
        currency: currentItem.currency || 'INR', 
        outOfStock: currentItem.outOfStock || false,
   
      });
    }
    if (!isEditMode) {
      setItemData({
        itemName: '',
        price: 0,
        veg: false,
        category: '',
        currency: 'INR',
        outOfStock: false,

        institution,
       
      });
    }
  }, [isEditMode, currentItem]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedValue = name === 'price' ? parseFloat(value) || 0 : value;
    setItemData((prevData) => ({ ...prevData, [name]: updatedValue }));
  };

  const handleCategoryChange = (e) => {
    const { value } = e.target;
    setItemData((prevData) => ({ ...prevData, category: value }));
    if (value === 'Other') {
      setItemData((prevData) => ({ ...prevData, category: '' }));
      setIsOtherSelected(true); 
    } else {
      setIsOtherSelected(false); 
    }
  };


  const handleVegChange = (value) => {
    setItemData((prevData) => ({ ...prevData, veg: value }));
  };
  return (
    <Modal show={showModal} size="lg" onClose={() => setShowModal(false)} popup>
      <div className="flex flex-row justify-center">
        <h2 className="text-xl mt-6">{isEditMode ? 'Edit Item' : 'Add Item'}</h2>
      </div>
      <Modal.Body>
        <div className="px-4 py-2">
    
          <div className="mb-2">
            <Label htmlFor="itemName" value="Item Name" />
            <TextInput
              id="itemName"
              name="itemName"
              placeholder="Enter the item name"
              value={itemData.itemName || ''}
              onChange={handleInputChange}
              required
              className="mt-1"
            />
          </div>

   
          
          <div className="mb-2">
            <Label htmlFor="price" value="Price (in INR)" />
            <TextInput
              id="price"
              name="price"
              type="number"
              value={itemData.price || ''}
              onChange={handleInputChange}
              required
              className="mt-1"
            />
          </div>

 
         
          {!isOtherSelected && (  // Only show the dropdown if "Other" is not selected
            <div className="mb-2">
              <Label htmlFor="category" value="Category" />
              <Select
                id="category"
                name="category"
                value={itemData.category || ''}
                onChange={handleCategoryChange}
                className="mt-1"
              >
                {categories.length > 0 ? (
                  categories.map((category, index) => (
                    <option key={index} value={category}>
                      {category}
                    </option>
                  ))
                ) : (
                  <option disabled>Loading categories...</option>
                )}
                <option value="Other">Other</option>
              </Select>
            </div>
          )}

          
          {isOtherSelected && (
            <div className="mb-2">
              <Label htmlFor="category" value="Custom Category" />
              <TextInput
                id="category"
                name="category"  
                placeholder="Enter custom category"
                value={itemData.category || ''}
                onChange={handleInputChange}
                required
                className="mt-1"
              />
            </div>
          )}
     
           <div className="mb-2">
            <Label value="Vegetarian" />
            <div className="flex gap-4">
              <div className="flex items-center">
                <input
                  type="radio"
                  id="vegTrue"
                  name="veg"
                  checked={itemData.veg === true}
                  onChange={() => handleVegChange(true)}
                  className="mr-2"
                />
                <Label htmlFor="vegTrue">Yes</Label>
              </div>
              <div className="flex items-center">
                <input
                  type="radio"
                  id="vegFalse"
                  name="veg"
                  checked={itemData.veg === false}
                  onChange={() => handleVegChange(false)}
                  className="mr-2"
                />
                <Label htmlFor="vegFalse">No</Label>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-center items-center mt-2 gap-5">
          <Button
           onClick={() => {
            handleSave(itemData); 
            setIsOtherSelected(false);
          }}
            className="w-full sm:w-auto bg-primaryColor"
            disabled={descriptionError} 
          >
            {isEditMode ? 'Save Changes' : 'Add Item'}
          </Button>
          <Button
            color="gray"
            onClick={() => {
              setShowModal(false); 
              setIsOtherSelected(false); 
            }}
            className="w-full sm:w-auto"
          >
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ItemModal;
