const VisitUs = () => {
  const schedule = [
    { day: 'Monday', time: '9:00 AM - 9:00 PM' },
    { day: 'Tuesday', time: '9:00 AM - 9:00 PM' },
    { day: 'Wednesday', time: '9:00 AM - 9:00 PM' },
    { day: 'Thursday', time: '9:00 AM - 9:00 PM' },
    { day: 'Friday', time: '9:00 AM - 9:00 PM' },
    { day: 'Saturday', time: '9:00 AM - 9:00 PM' },
    { day: 'Sunday', time: 'Closed' },
  ]
  return (
    <section className='flex flex-wrap gap-y-16 gap-x-32 justify-center items-center bg-secondaryColor'>
      <h2 className='w-[100%]'>Visit Us</h2>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d61775.455071125994!2d83.09808395852382!3d19.92222345633168!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a24ef3382020aa3%3A0x6720328dfbfd451a!2sGovernment%20College%20Of%20Engineering%2CKalahandi(Autonomous)!5e0!3m2!1sen!2sin!4v1730261519881!5m2!1sen!2sin"
        width="600" height="450" allowFullScreen="" loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"></iframe>
      <div className='w-96'>
        {schedule.map((item, index) => (
          <div key={item.day}>
            <div className='text-left'>{item.day}</div>
            <div className='text-right'>{item.time}</div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default VisitUs;